import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { datadogRum } from '@datadog/browser-rum';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from '@reach/router';
import { createOrder } from '../../../api/orderServiceApiRequests';
import { CreateOrderAddressForm } from './CreateOrderAddressFormGroup';
import { CreateOrderDropdown } from './CreateOrderDropdown';

import { CreateOrderUserFormGroup, USER_FORM_LABELS } from './CreateOrderUserFormGroup';
import { CollapsibleFields } from '../../../../components/collapsible/CollapsibleFields';
import { DATE_FIELDS, DateField } from '../../../../components/datefield/DateField';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { getErrors, getName } from '../../../../utils/formHelpers';
import { getFormattedRequest, US_COUNTRY_CODE } from './helpers/requestFormatterHelper';
import { ORDER_CREATED_SUCCESS, ORDER_NOT_CREATED_ERROR } from './CreateOrder';
import { RadioButtonFormGroup } from '../../../../components/radio/RadioButtonFormGroup';
import { REGEX } from '../../../../data/constants';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../store/snackbar/snackbar.action';

const baseClassName = 'mb-4 mr-4';

const isDateInThePast = (date) => {
  const now = moment();
  const inputDate = moment(date);

  return inputDate.isBefore(now, 'day');
};

const genderOptions = [
  {
    label: 'Female',
    value: 'f',
  },
  {
    label: 'Male',
    value: 'm',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    '& p': {
      visible: 'hidden',
      display: 'none',
    },
  },
}));

export const CreateOrderForm = ({ countryCodeList, onSubmit, productList }) => {
  const mounted = useRef(false);
  const classes = useStyles();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const productCode = query.get('productCode');
  const productCodeFromQuery = productCode?.toUpperCase();

  const [usePatientDetails, setUsePatientDetails] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [shouldDisplayUser, setShouldDisplayUser] = useState(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
    reset,
    setError,
    trigger,
    watch,
    setValue,
  } = useForm();
  const dispatch = useDispatch();

  const watchDeliveryCountryCode = watch('delivery.countryCode');
  const watchDateOfBirth = watch(`user.dateOfBirth`);
  const watchFirstName = watch(`user.${USER_FORM_LABELS.FIRST_NAME}`);
  const watchLastName = watch(`user.${USER_FORM_LABELS.LAST_NAME}`);
  const watchEmail = watch(`user.${USER_FORM_LABELS.EMAIL}`);
  const watchPhoneNumber = watch(`user.${USER_FORM_LABELS.PHONE_NUMBER}`);
  const isUSOrder = useMemo(() => watchDeliveryCountryCode?.country_code === US_COUNTRY_CODE, [watchDeliveryCountryCode]);
  const persistedUsePatientDetails = useRef();

  useEffect(() => {
    const updateDeliveryControl = (controlName) => {
      if (control.getValues().user?.[controlName] || control.getValues().delivery[controlName]) {
        setValue(`delivery.${controlName}`, control.getValues().user[controlName]);
        trigger(`delivery.${controlName}`);
      }
    };

    if (usePatientDetails) {
      Object.keys(USER_FORM_LABELS).forEach((field) => {
        updateDeliveryControl(USER_FORM_LABELS[field]);
      });

      // checking if usePatientDetails is the changed dependency,
      // so that existing delivery form values are not deleted unnecessarily
    } else if (!usePatientDetails && persistedUsePatientDetails.current !== usePatientDetails) {
      setValue('delivery', {
        [USER_FORM_LABELS.FIRST_NAME]: '',
        [USER_FORM_LABELS.LAST_NAME]: '',
        [USER_FORM_LABELS.EMAIL]: '',
        [USER_FORM_LABELS.PHONE_NUMBER]: '',
      });
    }
    persistedUsePatientDetails.current = usePatientDetails;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usePatientDetails, watchFirstName, watchLastName, watchEmail, watchPhoneNumber]);

  const { clinicId } = useSelector(({ user }) => ({ clinicId: user.profile.clinic?.id }));

  const onProductChange = (product) => {
    if (product) {
      const { phlebotomyType, registrationType } = product;
      const displayUser = (!!phlebotomyType && phlebotomyType !== 'NONE') || registrationType === 'AUTOMATIC_WITH_USER_INFO';
      setShouldDisplayUser(displayUser);
      if (!displayUser) {
        setUsePatientDetails(false);
      }
    }
  };

  async function onFormSubmit(formData) {
    setIsFormLoading(true);
    try {
      const response = await createOrder({
        payload: getFormattedRequest({ formData, isUSOrder, partnerId: clinicId, hasUserDetails: shouldDisplayUser }),
      });
      showSuccessSnackbar(ORDER_CREATED_SUCCESS)(dispatch);
      reset();
      onSubmit(response.data.orderNumber);
    } catch (error) {
      const detailErrors = error?.response?.data?.details?.map(({ error: errorDetails }) => errorDetails);
      showErrorSnackbar(detailErrors || error?.response?.data?.message || ORDER_NOT_CREATED_ERROR)(dispatch);
      datadogRum.addError(error?.response?.data);
    } finally {
      if (mounted.current) {
        setIsFormLoading(false);
      }
    }
  }

  return (
    <form className="max-w-lg m-auto" onSubmit={handleSubmit(onFormSubmit)}>
      <h2 className="text-lg font-medium border-b mb-6">Order</h2>
      <Box component="div">
        <Box component="h3" className={baseClassName}>
          Select product
        </Box>
        <CreateOrderDropdown
          testId="product"
          name="product"
          defaultValue={productCodeFromQuery && productList.find((product) => product.productCode === productCodeFromQuery)}
          control={control}
          isDisabled={isFormLoading}
          options={productList}
          onValueChange={onProductChange}
          getOptionLabel={(product) =>
            `${product.title} (${product.productCode}) - ${new Intl.NumberFormat(navigator.language, {
              style: 'currency',
              currency: product.currency,
            }).format(product.price)}`
          }
          label="Product"
          error={!!errors.product}
          helperText={formErrorMessages(errors.product)}
          taxHelperText={'Note: All prices are shown ex VAT/tax, which will be added on as applicable.'}
        />

        <Controller
          as={TextField}
          className={baseClassName}
          label="Quantity"
          id="Quantity"
          name="quantity"
          inputProps={{
            inputMode: 'numeric',
          }}
          disabled={isFormLoading}
          error={!!errors.quantity}
          helperText={formErrorMessages(errors.quantity)}
          rules={{ required: true, pattern: REGEX.DIGITS }}
          data-testid="Quantity"
          defaultValue={1}
          control={control}
        />
      </Box>

      {shouldDisplayUser && (
        <CollapsibleFields className="my-4" header="Patient details">
          <Controller
            as={TextField}
            className={baseClassName}
            label="Title"
            id={getName({ groupName: 'user', property: 'title' })}
            name={getName({ groupName: 'user', property: 'title' })}
            disabled={isFormLoading}
            error={!!getErrors({ errors, groupName: 'user', property: 'title' })}
            helperText={formErrorMessages(getErrors({ errors, groupName: 'user', property: 'title' }))}
            control={control}
            defaultValue={''}
          />
          <CreateOrderUserFormGroup
            className={baseClassName}
            control={control}
            countryCodeList={countryCodeList}
            errors={errors}
            groupName="user"
            isDisabled={isFormLoading}
            getErrorClasses={(property) => (getErrors({ errors, groupName: 'user', property }) ? {} : { root: classes.root })}
          />
          <RadioButtonFormGroup
            control={control}
            ariaLabel="patient-details-sex-at-birth"
            className={baseClassName}
            error={!!getErrors({ errors, groupName: 'user', property: 'gender' })}
            errorMessage={formErrorMessages(getErrors({ errors, groupName: 'user', property: 'gender' }))}
            id="user.gender"
            isDisabled={isFormLoading}
            label="Sex at birth"
            name="user.gender"
            options={genderOptions}
          />
          <DateField
            groupName="user.dateOfBirth"
            className={baseClassName}
            errors={errors}
            formHelperText={'For example, 13 01 2007'}
            getErrorClasses={(property) => (getErrors({ errors, groupName: 'user.dateOfBirth', property }) ? {} : { root: classes.root })}
            isDisabled={isFormLoading}
            isUSFormat={false}
            label="Date of Birth"
            onBlur={async () => {
              const groupName = 'user.dateOfBirth';
              clearErrors(groupName);
              await trigger([
                getName({ groupName, property: DATE_FIELDS.DAY.property }),
                getName({ groupName, property: DATE_FIELDS.MONTH.property }),
                getName({ groupName, property: DATE_FIELDS.YEAR.property }),
              ]);

              if (
                !getErrors({ errors, groupName, property: DATE_FIELDS.DAY.property }) &&
                !getErrors({ errors, groupName, property: DATE_FIELDS.MONTH.property }) &&
                !getErrors({ errors, groupName, property: DATE_FIELDS.YEAR.property })
              ) {
                const dateOfBirth = [
                  watchDateOfBirth[DATE_FIELDS.YEAR.property],
                  watchDateOfBirth[DATE_FIELDS.MONTH.property],
                  watchDateOfBirth[DATE_FIELDS.DAY.property],
                ].join('-');

                if (!isDateInThePast(dateOfBirth)) {
                  setError(groupName, { type: 'custom', message: 'Date of birth must be in the past' });
                }
              }
            }}
            control={control}
            rootClassName="my-px-4"
          />
        </CollapsibleFields>
      )}

      <CollapsibleFields className="my-4" header="Delivery details">
        {shouldDisplayUser && (
          <FormControl>
            <FormControlLabel
              label="Use patient details"
              control={
                <Checkbox
                  name="usePatientDetails"
                  checked={usePatientDetails}
                  onChange={(event) => setUsePatientDetails(event.target.checked)}
                  color="default"
                  data-testid="use-patient-details"
                />
              }
            />
          </FormControl>
        )}
        <CreateOrderUserFormGroup
          className={baseClassName}
          control={control}
          countryCodeList={countryCodeList}
          errors={errors}
          groupName="delivery"
          isDisabled={isFormLoading || usePatientDetails}
          getErrorClasses={(property) => (getErrors({ errors, groupName: 'delivery', property }) ? {} : { root: classes.root })}
        />
        <CreateOrderAddressForm
          className={baseClassName}
          control={control}
          countryCodeList={countryCodeList}
          errors={errors}
          groupName="delivery"
          isDisabled={isFormLoading}
          isUSOrder={isUSOrder}
        />
      </CollapsibleFields>

      <div className="text-right pt-3 border-t mt-4">
        <Button className="ml-5" type="submit" disabled={isFormLoading} startIcon={isFormLoading && <CircularProgress />}>
          Submit order
        </Button>
      </div>
    </form>
  );
};

CreateOrderForm.propTypes = {
  countryCodeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      country_code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      productCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
