import { useEffect, useState } from 'react';
import { TabPanel, TabContext } from '@mui/lab';
import { Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { SimulateForm } from './SimulateForm';
import { useDataApi } from '../../../../hooks';
import { getProductVariationsUrl } from '../../../api/productVariations';
import { ERROR } from '../api-keys/actions/DeleteIconAction';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../store/snackbar/snackbar.action';
import { createTestData } from '../../../api/dataSimulator';
import { SimulateFormResult } from './simulate-form/result/SimulateFormResult';

export const SIMULATOR_INNER_TABS = {
  seed: 'seed',
  order: 'order',
  dispatch: 'dispatch',
  register: 'register',
  labAccession: 'labAccession',
  result: 'result',
};

const formatProductLabel = (productVariation) => {
  const phlebotomySuffix =
    productVariation.phlebotomyType === 'NONE' ? '' : ` - ${productVariation.phlebotomyType.replace(/\w+/g, capitalize)} Phlebotomy`;
  return `${productVariation.title} (${productVariation.productCode})${phlebotomySuffix}`;
};

const Simulator = () => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(SIMULATOR_INNER_TABS.order);
  const [productVariations, setProductVariations] = useState([]);
  const [productVariationsWithRegistration, setProductVariationsWithRegistration] = useState([]);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const clinicId = useSelector(({ user }) => user?.profile?.clinic?.id);

  const productsVariationsUrl = clinicId ? getProductVariationsUrl(clinicId) : null;
  const [{ isLoading: isProductLoading, data = {}, isError }] = useDataApi(productsVariationsUrl, null, true);

  const [orderResponseData, setOrderResponseData] = useState({
    [SIMULATOR_INNER_TABS.seed]: {},
    [SIMULATOR_INNER_TABS.order]: {},
    [SIMULATOR_INNER_TABS.dispatch]: {},
    [SIMULATOR_INNER_TABS.register]: {},
    [SIMULATOR_INNER_TABS.labAccession]: {},
    [SIMULATOR_INNER_TABS.result]: {},
  });

  useEffect(() => {
    if (!isProductLoading && !isError) {
      const products = data?.productVariations.map((productVariation) => ({
        label: formatProductLabel(productVariation),
        value: productVariation.productCode,
        registrationless: productVariation.registrationless,
      }));
      const sortedProducts = products.sort((p1, p2) => p1.label.localeCompare(p2.label));
      setProductVariations(sortedProducts);
      setProductVariationsWithRegistration(sortedProducts.filter((p) => p.registrationless !== true));
    }

    if (!isProductLoading && isError) {
      showErrorSnackbar(ERROR)(dispatch);
    }
  }, [data, isProductLoading, isError, dispatch]);

  const selectTab = (event, newTab) => setSelectedTabIndex(newTab);

  const onFormSubmit = async (formData, type, product) => {
    setIsFormDisabled(true);

    let payload = {};
    let standalone = false;

    if (formData.orderId) {
      payload.orderNumber = formData.orderId;
      standalone = true;
    } else if (formData.kitId) {
      payload.existingKit = {
        kitId: formData.kitId,
      };
      // Only needed for registration
      if (formData.existingEmail && formData.existingPassword) {
        payload.email = formData.existingEmail;
        payload.password = formData.existingPassword;
      }
      standalone = true;
    } else {
      payload = {
        product,
        email: formData.email,
      };
      if (formData.password) {
        payload.password = formData.password;
      }
    }

    setIsStandalone(standalone);
    try {
      const response = await createTestData(type, standalone, formData.batch, payload);
      showSuccessSnackbar('Request successful! See below for details.')(dispatch);
      setOrderResponseData({ ...orderResponseData, [type]: response.data });
    } catch (error) {
      const message = error?.response?.data?.message || 'Error with request. Please try again.';
      if (message.includes('singinup a new user')) {
        showErrorSnackbar('Email is already registered. Please use a different email.')(dispatch);
      } else {
        showErrorSnackbar(message)(dispatch);
      }
    } finally {
      setIsFormDisabled(false);
    }
  };

  const isProductVariationsDisabled = isProductLoading || isError;
  return (
    <div className="pt-5 px-5 pb-5">
      <p className="pb-5">
        This tool will allow you to generate test data for testing your application. You can either simulate the whole flow up to a
        particular step, or provide an existing order number to just simulate that step. It will fire all the appropriate webhooks and
        notifications.
      </p>

      <Tabs value={selectedTabIndex} onChange={selectTab}>
        <Tab value={SIMULATOR_INNER_TABS.seed} label="Batch" />
        <Tab value={SIMULATOR_INNER_TABS.order} label="Order" />
        <Tab value={SIMULATOR_INNER_TABS.dispatch} label="Dispatched" />
        <Tab value={SIMULATOR_INNER_TABS.register} label="Registered" />
        <Tab value={SIMULATOR_INNER_TABS.labAccession} label="Lab Accessioned" />
        <Tab value={SIMULATOR_INNER_TABS.result} label="Results" />
      </Tabs>

      <TabContext value={selectedTabIndex}>
        <TabPanel value={SIMULATOR_INNER_TABS.seed}>
          <SimulateForm
            type={SIMULATOR_INNER_TABS.seed}
            descriptionNew="Create datasets in batches for the partner."
            descriptionExisting=""
            productVariations={productVariations}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.seed]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>
        <TabPanel value={SIMULATOR_INNER_TABS.order}>
          <SimulateForm
            type={SIMULATOR_INNER_TABS.order}
            descriptionNew="Create a new order."
            descriptionExisting=""
            productVariations={productVariations}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.order]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>

        <TabPanel value={SIMULATOR_INNER_TABS.dispatch}>
          <SimulateForm
            type={SIMULATOR_INNER_TABS.dispatch}
            descriptionNew="Create a new order that is marked as dispatched."
            descriptionExisting="Move an existing order to dispatched."
            productVariations={productVariations}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.dispatch]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>

        <TabPanel value={SIMULATOR_INNER_TABS.register}>
          <SimulateForm
            type={SIMULATOR_INNER_TABS.register}
            descriptionNew="Create a new order that is already registered."
            descriptionExisting="Register an existing order."
            productVariations={productVariationsWithRegistration}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.register]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>

        <TabPanel value={SIMULATOR_INNER_TABS.labAccession}>
          <SimulateForm
            type={SIMULATOR_INNER_TABS.labAccession}
            descriptionNew="Create a new test that is already registered and marked received by the lab (accessioned)."
            descriptionExisting="Accession an existing registered test."
            productVariations={productVariations}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.labAccession]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>

        <TabPanel value={SIMULATOR_INNER_TABS.result}>
          <SimulateFormResult
            type={SIMULATOR_INNER_TABS.result}
            descriptionNew="Create a new test that already has results."
            descriptionExisting="Give results to an already accessioned test."
            productVariations={productVariations}
            isProductVariationsDisabled={isProductVariationsDisabled}
            onFormSubmit={onFormSubmit}
            orderResponseData={orderResponseData[SIMULATOR_INNER_TABS.result]}
            isStandalone={isStandalone}
            isFormDisabled={isFormDisabled}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Simulator;
